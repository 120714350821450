// @ts-nocheck
import { useState, useEffect } from "react";
import {
  Route,
  Redirect,
  Switch,
  useLocation,
  useHistory,
} from "react-router-dom";
import { Frame, Icon, TopBar, VisuallyHidden } from "@shopify/polaris";
import useToken from "../hooks/useToken";
import { useShopStore } from "../hooks/shopStore";
import Login from "../components/Login/Login";
import Pricing from "../components/Pricing/index";
import Widget from "../components/Widget/Widget";
import SideMenu from "../common/SideMenu";
import Support from "../components/Support/Support";
import Templates from "../components/Templates/index";
import ChatSettings from "../components/Widget/ChatSettings";
import { logoutUser } from "../redux/actions/login";
import DowntimeMarkup from "../common/DowntimeMarkup";
import CreateTemplate from "../components/Messaging/ExtensionTemplates/CreateTemplate";
import Confirmation from "../common/Confirmation";
import AutomatedAbandonedCart from "../components/AbandonedCart";
import AutomatedCRMPage from "../components/OrdersCRM/index";
import AutomatedCoDPage from "../components/CashOnDelivery/index";
import CustomersPage from "../components/OptinNumber/index";
import PrivateWABA from "../components/PrivateWABA/index";
import AdminConfiguration from "../components/AdminConfiguration/AdminConfiguration";
import EmailTemplates from "../components/Email/Templates";
import Release_22_02_03_Modal from "../components/WhatsNew/R22.02.03";
import Release_22_03_01_Modal from "../components/WhatsNew/R22.03.01";
import Release_22_05_01_Modal from "../components/WhatsNew/R23.05.01";
import BellStatic from "../assets/icons/bell-static.png";
import BellNotification from "../assets/icons/bell-notification.gif";
import EmailConfiguration from "../components/Email/Configuration/Configuration";
import HomePage from "../components/HomePage/index";
import "../App.scss";
import { CancelSmallMinor } from "@shopify/polaris-icons";
import MarketingCampaigns from "../components/MarketingCampaigns";
import WhatsappTemplates from "../components/WhatsappTemplates";
import WABA from "../components/WABA";
import Chatbot from "../components/Chatbot";

import { clevertapEventPush, CT_EVENT_IDS } from "../utils/clevertapUtils";
import {
  PLAN_OLD_FREE,
  APP_NAME,
  RIBBON,
  PLATFORM,
  MAINTENANCE_FLAG,
  MAINTENANCE_MESSAGE,
  PLAN_NEW_FREE,
  PLAN_OLD_PRO,
  PLAN_OLD_ULTIMATE,
  PLAN_SMALL_BUSINESS,
  PLAN_ENTERPRISE,
  PLAN_ENGAGE_WIX,
  OLD_PLANS_RELEASE_NOTES,
  SHARED_WABA_DISCONTINUE,
} from "../constants/constants";

import {
  PlanIcon,
  SupportIcon,
  ReviewIcon,
  LogoutIcon,
} from "../common/SvgIcons";
import TeamInbox from "../components/TeamInbox";
import Messaging from "../components/Messaging";
import Wallet from "../components/Wallet";
import moment from "moment";
import Chat from "../components/AgentAssist/Chat";
import Configurations from "../components/AgentAssist/Configurations";
// Firebase
import { remoteConfig } from "../utils/firebase";
import { fetchAndActivate, getValue } from "firebase/remote-config";
import AnalyticsMessageLogs from "../components/Analytics_MessageLogs";
import ReleaseNotesModal from "../components/Modals/ReleaseNotesModal/ReleaseNotesModal";
import IdleTimeoutPopup from "../components/Modals/IdleTimeoutPopup";
import ProtectedRoute from "../hooks/protectedRoute";
import { privateWabaPresent } from "../utils/common";

const PrivateRoutes = (props: any) => {
  const { token, setToken } = useToken();
  const {
    shopDetailsData,
    shopWabaDetails,
    shopPrivateWabaDetails,
    shopWABAVerificationDetails,
    shopOwnerDetails,
    agentPhone,
    walletRenewalInfo,
    showSharedTeamInbox,
    resetShopStore,
  } = useShopStore();
  const [showMenu, setShowMenu] = useState(false);
  const [showHomePage, setShowHomePage] = useState(false);
  const [showMobileNavigation, setShowMobileNavigation] = useState(false);
  const [shopDetails, setShopDetails] = useState<any>();
  const [privateWabaDetails, setPrivateWabaDetails] = useState<any>();
  const [planId, setPlanId] = useState<any>();
  const [userMenuClicked, setUserMenuClicked] = useState(false);
  const [selectedTabForTemplate, setSelectedTabForTemplate] = useState(0);
  const [showSharedInbox, setShowSharedInbox] = useState(false);
  const [showHomePageUI, setShowHomePageUI] = useState(false);
  const [showWhatsNewTooltip, setShowWhatsNewTooltip] = useState(false);
  const [showReleaseModal_22_02_03, setShowReleaseModal_22_02_03] =
    useState(false);
  const [showReleaseModal_22_03_01, setShowReleaseModal_22_03_01] =
    useState(false);
  const [showReleaseModal_22_05_01, setShowReleaseModal_22_05_01] =
    useState(false);
  const [showReleaseModal_23_08_16, setShowReleaseModal_23_08_16] =
    useState(false);
  const [releaseNotesData, setReleaseNotesData] = useState({});
  const [ribbon, setRibbon] = useState({
    show: false,
    reason: "",
    dueDate: "",
  });
  const [maintainaceOn, setMaintainaceOn] = useState(false);
  const [privateWabaRequiredRibbon, setPrivateWabaRequiredRibbon] =
    useState(true);
  const bellClicked = localStorage.getItem("bell_clicked")
    ? localStorage.getItem("bell_clicked")
    : false;
  const location = useLocation();
  const history = useHistory();
  let currentPath = history.location.pathname;

  useEffect(() => {
    remoteConfig.settings.minimumFetchIntervalMillis = 0;
    getMaintenanceStatus();
    //eslint-disable-next-line
  }, []);

  const getMaintenanceStatus = async () => {
    let remoteconfigValue: any = {};
    fetchAndActivate(remoteConfig).then(() => {
      remoteconfigValue = getValue(remoteConfig, MAINTENANCE_FLAG);
      setMaintainaceOn(JSON.parse(remoteconfigValue?._value));
    });
  };

  useEffect(() => {
    let productTourViewed = JSON.parse(
      sessionStorage.getItem("productTourViewed") || "[]"
    );
    let pathName =
      location.pathname === "/"
        ? `/home_${shopDetails?.planId}`
        : `${location.pathname}_${shopDetails?.planId}`;
    if (!productTourViewed.includes(pathName)) {
      setShowMobileNavigation(true);
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    let planIdInt = parseInt(planId);
    if ([PLAN_OLD_FREE, PLAN_NEW_FREE].includes(planIdInt))
      setReleaseNotesData(OLD_PLANS_RELEASE_NOTES.OLD_FREE);
    if (planIdInt === PLAN_OLD_PRO)
      setReleaseNotesData(OLD_PLANS_RELEASE_NOTES.OLD_PRO);
    if (planIdInt === PLAN_OLD_ULTIMATE)
      setReleaseNotesData(OLD_PLANS_RELEASE_NOTES.OLD_ULTIMATE);
    if (planIdInt === PLAN_SMALL_BUSINESS)
      setReleaseNotesData(OLD_PLANS_RELEASE_NOTES.GROWTH);
    if ([PLAN_ENGAGE_WIX, PLAN_ENTERPRISE].includes(planIdInt))
      setReleaseNotesData(OLD_PLANS_RELEASE_NOTES.ENGAGE);

    if (location.search.includes("showreleasenotes=true"))
      setShowReleaseModal_23_08_16(true);
    //eslint-disable-next-line
  }, [planId]);

  useEffect(() => {
    if (privateWabaPresent(shopWabaDetails, shopPrivateWabaDetails) || (PLAN_OLD_FREE === parseInt(shopDetails?.planId)))
      setPrivateWabaRequiredRibbon(false);

    if (
      shopDetailsData &&
      shopWabaDetails &&
      shopOwnerDetails &&
      shopPrivateWabaDetails &&
      shopWABAVerificationDetails
    ) {
      setShowHomePageUI(true);
    }

    if (shopDetailsData) {
      showOnBoardingFlow(shopDetailsData);
      setPlanId(shopDetailsData?.planId);
      setShopDetails(shopDetailsData);
      setShowMenu(true);
      setShowHomePage(true);
    }

    if (shopWabaDetails) setPrivateWabaDetails(shopWabaDetails);

    if (shopOwnerDetails) {
      props.setOwnerDetails(shopOwnerDetails);
      // Send profile details after owner details has been fetched
      clevertapEventPush({
        eventId: CT_EVENT_IDS.USER_PUSH,
        eventData: {},
      });
    }

    if (walletRenewalInfo) {
      if (walletRenewalInfo?.message?.balance) {
        walletRenewalInfo.message.balance = Number(
          walletRenewalInfo?.message?.balance
        );
      }
      if (
        walletRenewalInfo?.message?.renewalTime &&
        moment(Number(walletRenewalInfo?.message?.renewalTime))
          .subtract(5, "days")
          .valueOf() < moment().valueOf()
      ) {
        let data = {
          show: true,
          reason: "rechargeWarning",
          dueDate: moment(
            Number(walletRenewalInfo?.message?.renewalTime)
          ).format("Do MMMM"),
        };
        walletRenewalInfo.message["ribbon"] = data;
        setRibbon(data);
      } else if (
        walletRenewalInfo?.message?.balance &&
        Number(walletRenewalInfo?.message?.balance) <= 0.09
      ) {
        let data = {
          ...ribbon,
          show: true,
          reason: "insufficientBal",
        };
        walletRenewalInfo.message["ribbon"] = data;
        setRibbon(data);
      } else {
        let data = {
          ...ribbon,
        };
        walletRenewalInfo.message["ribbon"] = data;
        setRibbon(data);
      }
      localStorage.setItem(
        "walletRenewalStatus",
        JSON.stringify(walletRenewalInfo.message)
      );
    }

    if (showSharedTeamInbox) setShowSharedInbox(showSharedTeamInbox);
    //eslint-disable-next-line
  }, [
    shopDetailsData,
    shopWabaDetails,
    shopOwnerDetails,
    shopPrivateWabaDetails,
    shopWABAVerificationDetails,
    walletRenewalInfo,
  ]);

  const handleMobileNavigation = () => {
    if (token) {
      setShowMobileNavigation((showMobileNavigation) => !showMobileNavigation);
    }
  };

  const showOnBoardingFlow = (shopData: any) => {
    if (
      shopData?.planId &&
      PLAN_OLD_FREE === parseInt(shopData.planId) &&
      shopData?.newUser &&
      ["/", "/home"].includes(currentPath) &&
      !sessionStorage.getItem("onboarding_flow_seen")
    ) {
      // setShowFrame(false);
      history.push("/onboard");
    }
  };

  const changePath = (path: any) => {
    history.push(path);
  };
  const logout = async () => {
    await logoutUser();
    await resetShopStore();
    setToken("");
    removeToken();
    history.push("/login");
  };

  const { removeToken } = useToken();
  // TODO Add user image in place of initials
  const userMenuMarkup = token ? (
    <div className="topBarContainer">
      <TopBar.UserMenu
        name={shopOwnerDetails?.name}
        detail={shopOwnerDetails?.shopName}
        initials={shopOwnerDetails?.name?.charAt(0)}
        actions={[
          {
            items: [
              {
                content: "Plans",
                onAction: () => {
                  changePath("/pricing");
                },
                icon: PlanIcon,
              },
              {
                content: "Support",
                onAction: () => {
                  clevertapEventPush({
                    eventId: CT_EVENT_IDS.EMAIL_SUPPORT_CLICKED,
                    eventData: {},
                  });
                  window.open(
                    "mailto:support@superlemon.xyz?subject=I%20need%20help%20with%20WhatsApp%20chat%20—%20" +
                      shopDetails?.url,
                    "_blank"
                  );
                },
                icon: SupportIcon,
              },
              {
                content: "Write a Review",
                onAction: () => {
                  clevertapEventPush({
                    eventId: CT_EVENT_IDS.WRITE_A_REVIEW_CLICKED,
                    eventData: {},
                  });
                  let link =
                    shopDetails?.platform === PLATFORM.SHOPIFY
                      ? "https://apps.shopify.com/" +
                        APP_NAME +
                        "?#modal-show=ReviewListingModal"
                      : "https://www.wix.com/app-market";
                  window.open(link);
                },
                icon: ReviewIcon,
              },
              {
                content: "Logout",
                onAction: () => {
                  clevertapEventPush({
                    eventId: CT_EVENT_IDS.LOGGED_OUT,
                    eventData: {},
                  });
                  logout();
                },
                icon: LogoutIcon,
              },
            ],
          },
        ]}
        open={userMenuClicked}
        onToggle={() => {
          setUserMenuClicked(!userMenuClicked);
        }}
      />
      {token ? (
        <div
          className={
            userMenuClicked
              ? "DropDownArrowTopBar-pressed"
              : "DropDownArrowTopBar"
          }
          onClick={() => {
            setUserMenuClicked(!userMenuClicked);
          }}
        >
          <i className="arrow arrow-down"></i>
        </div>
      ) : null}
    </div>
  ) : null;

  const secondaryMenuMarkup = token ? (
    <TopBar.Menu
      activatorContent={
        <span>
          <span className="bell-icon">
            <img
              src={bellClicked ? BellStatic : BellNotification}
              alt="Release Notes"
              className={bellClicked ? "" : "notification-bell"}
            />
          </span>
          <VisuallyHidden>Secondary menu</VisuallyHidden>
        </span>
      }
      open={showWhatsNewTooltip}
      onOpen={() => {
        setShowWhatsNewTooltip(true);
        localStorage.setItem("bell_clicked", "true");
      }}
      onClose={() => setShowWhatsNewTooltip(false)}
      actions={[
        {
          items: [
            {
              content: "Whats New!",
              onAction: () => {
                alert("Please click on any of the release versions");
              },
            },
          ],
        },
        {
          items: [
            {
              content: "Release 23.08.16 *New*",
              onAction: () => {
                setShowReleaseModal_23_08_16(true);
              },
            },
          ],
        },
        {
          items: [
            {
              content: "Release 23.01.09",
              onAction: () => {
                setShowReleaseModal_22_05_01(true);
              },
            },
          ],
        },
        {
          items: [
            {
              content: "Release 22.03.01",
              onAction: () => {
                setShowReleaseModal_22_03_01(true);
              },
            },
          ],
        },
      ]}
    />
  ) : null;

  const topBarMarkup = (
    <div>
      <TopBar
        userMenu={token ? userMenuMarkup : null}
        secondaryMenu={secondaryMenuMarkup}
        showNavigationToggle
        onNavigationToggle={handleMobileNavigation}
      />
      {maintainaceOn && (
        <div className="ribbonMessage">
          <div>
            <p>{MAINTENANCE_MESSAGE}</p>
          </div>
          <div onClick={() => setMaintainaceOn(false)}>
            <Icon source={CancelSmallMinor} color="base" />
          </div>
        </div>
      )}
      {privateWabaRequiredRibbon && (
        <div className="ribbonMessage">
          <div className="wid85">
            <p>{SHARED_WABA_DISCONTINUE}</p>
          </div>
          <div className="wid10">
            <button onClick={() => history.push("/private-waba")}>{"Create WABA"}</button>
          </div>
          <div onClick={() => setPrivateWabaRequiredRibbon(false)}>
            <Icon source={CancelSmallMinor} color="base" />
          </div>
        </div>
      )}

      {ribbon.show && (
        <div
          className={
            ribbon.reason === "insufficientBal"
              ? "ribbonMessageRed"
              : "ribbonMessage"
          }
        >
          <div>
            {RIBBON[ribbon.reason as keyof typeof RIBBON].text.replace(
              "{dueDate}",
              ribbon.dueDate
            )}
          </div>
          <div
            onClick={() => {
              setRibbon((prevState) => {
                return { ...prevState, show: false };
              });
            }}
            className="ribbonClose"
          >
            <Icon source={CancelSmallMinor} color="base" />
          </div>
        </div>
      )}
    </div>
  );
  const navigationMarkup = showMenu ? (
    <SideMenu
      setToken={setToken}
      planId={planId}
      shopDetails={shopDetails}
      privateWabaDetails={privateWabaDetails}
      showSharedInbox={showSharedInbox}
      agentPhone={agentPhone}
    />
  ) : null;

  return (
    <ProtectedRoute>
      <Frame
        topBar={topBarMarkup}
        navigation={navigationMarkup}
        showMobileNavigation={showMobileNavigation}
        onNavigationDismiss={handleMobileNavigation}
      >
        {!token ? <Redirect to="/login" /> : null}
        <DowntimeMarkup />
        <Release_22_02_03_Modal
          open={showReleaseModal_22_02_03}
          closeModal={() => setShowReleaseModal_22_02_03(false)}
        />
        <Release_22_03_01_Modal
          open={showReleaseModal_22_03_01}
          closeModal={() => setShowReleaseModal_22_03_01(false)}
        />
        <Release_22_05_01_Modal
          open={showReleaseModal_22_05_01}
          closeModal={() => setShowReleaseModal_22_05_01(false)}
        />
        <ReleaseNotesModal
          data={releaseNotesData}
          showReleaseNotes={showReleaseModal_23_08_16}
          setShowReleaseNotes={setShowReleaseModal_23_08_16}
        />
        <IdleTimeoutPopup />
        <Switch>
          <Route path="/home" onEnter={showHomePageUI}>
            <HomePage
              planId={planId}
              showReleaseModal_23_08_16={showReleaseModal_23_08_16}
              setShowReleaseModal_23_08_16={setShowReleaseModal_23_08_16}
              privateWabaDetails={privateWabaDetails}
            />
          </Route>
          <Route exact path="/widget" render={() => <Widget />} />
          <Route path="/widget/settings" render={() => <ChatSettings />} />
          <Route path="/pricing">
            <Pricing setPlanId={setPlanId} />
          </Route>
          <Route path="/support" component={Support} />
          <Route
            path="/templates"
            render={() => (
              <Templates
                privateWabaDetails={privateWabaDetails}
                selectedTabForTemplate={selectedTabForTemplate}
              />
            )}
          />
          <Route
            path="/campaigns"
            render={(props) => (
              <MarketingCampaigns
                {...props}
                privateWabaDetails={privateWabaDetails}
              />
            )}
          />
          <Route
            path="/whatsapp-templates"
            render={(props) => (
              <WhatsappTemplates
                {...props}
                privateWabaDetails={privateWabaDetails}
              />
            )}
          />
          <Route exact path="/email-templates">
            <EmailTemplates />
          </Route>
          <Route exact path="/email-config">
            <EmailConfiguration />
          </Route>
          <Route
            path="/templates/create"
            render={(props) => (
              <CreateTemplate
                {...props}
                setSelectedTabForTemplate={setSelectedTabForTemplate}
              />
            )}
          />
          <Route
            path="/messaging/create"
            render={(props) => (
              <CreateTemplate
                {...props}
                setSelectedTabForTemplate={setSelectedTabForTemplate}
              />
            )}
          />
          <Route exact path="/confirmation" component={Confirmation} />
          <Route
            path="/automated-abandoned-cart"
            component={AutomatedAbandonedCart}
          />
          <Route path="/automated-orders" component={AutomatedCRMPage} />
          <Route path="/automated-cod" component={AutomatedCoDPage} />
          <Route
            path="/private-waba"
            render={() =>
              privateWabaDetails && !privateWabaDetails.embedded ? (
                <PrivateWABA />
              ) : (
                <WABA />
              )
            }
          />
          <Route
            path="/optins"
            render={() => <CustomersPage selectedTabForCustomersPage={0} />}
          />
          <Route exact path="/admin-config">
            <AdminConfiguration />
          </Route>
          <Route
            path="/analytics-message-logs"
            render={() => <AnalyticsMessageLogs />}
          />
          <Route exact path="/">
            {!showHomePage ? (
              <Login
                setToken={setToken}
                setOwner={() => setOwnerDetails()}
                hideFrame={props.hideFrame}
              />
            ) : (
              <HomePage
                planId={planId}
                showReleaseModal_23_08_16={showReleaseModal_23_08_16}
                setShowReleaseModal_23_08_16={setShowReleaseModal_23_08_16}
                privateWabaDetails={privateWabaDetails}
              />
            )}
          </Route>
          {shopDetails?.platform === PLATFORM.SHOPIFY && (
            <Route path="/chatbot">
              <Chatbot planId={planId} />
            </Route>
          )}
          {showSharedInbox && (
            <Route path="/team-inbox">
              <TeamInbox />
            </Route>
          )}
          <Route path="/messaging" render={() => <Messaging />} />
          <Route path="/chat">
            <Chat />
          </Route>
          <Route path="/chat-config">
            <Configurations />
          </Route>
          {shopDetails?.platform === PLATFORM.WIX && (
            <Route path="/wallet">
              <Wallet />
            </Route>
          )}
        </Switch>
      </Frame>
    </ProtectedRoute>
  );
};

export default PrivateRoutes;
