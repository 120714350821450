import { Modal } from "@shopify/polaris";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import useToken from "../../../hooks/useToken";
import { logoutUser } from "../../../redux/actions/login";
import { IDLE_TIMEOUT_VALUE } from "../../../constants/constants";

const IdleTimeoutPopup = (props: any) => {
  const history = useHistory();
  const { removeToken, setToken } = useToken();
  const [isIdle, setIsIdle] = useState(false);
  const [lastActive, setLastActive] = useState(Date.now());
  const [timeLeft, setTimeLeft] = useState(10);
  const [timedOut, setTimedOut] = useState(false);
  const idleTime = IDLE_TIMEOUT_VALUE;

  useEffect(() => {
    const events = ["mousemove", "keydown", "mousedown", "touchstart"];
    const resetTimer = () => {
      setLastActive(Date.now());
      setTimeLeft(10); // Reset the countdown
      setTimedOut(false);
    };

    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    const checkIdle = setInterval(() => {
      if (Date.now() - lastActive >= idleTime) {
        setIsIdle(true);
      }
    }, 1000);

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, resetTimer);
      });
      clearInterval(checkIdle);
    };
  }, [lastActive]);

  useEffect(() => {
    let popupTimeout: any;
    let countdownInterval: any;

    if (isIdle) {
      countdownInterval = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(countdownInterval);
            setTimedOut(true);
            logout();
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }

    return () => {
      clearTimeout(popupTimeout);
      clearInterval(countdownInterval);
    };
  }, [isIdle]);

  const logout = async () => {
    await logoutUser();
    setToken("");
    removeToken();
    history.push("/login");
  };

  const continueSession = () => {
    setLastActive(Date.now());
    setIsIdle(false);
  }

  return (
    <Modal
      open={isIdle}
      sectioned={true}
      title={"Your session is about to expire"}
      onClose={() => {
        setIsIdle(false);
      }}
    >
      <Modal.Section>
        <div className={`release-notes d-flex-flow-col`}>
          <div style={{ textAlign: "center", paddingBottom: "2rem" }}>
            <p>
              Your session is about to expire due to inactivity, You will be
              logged out in {timeLeft} seconds.
            </p>
          </div>
          <div style={{ textAlign: "center" }}>
            <button
              onClick={() => continueSession()}
              style={{
                backgroundColor: "#5ec127",
                color: "white",
                padding: "10px 35px",
                cursor: "pointer",
              }}
            >
              Continue
            </button>
          </div>
        </div>
      </Modal.Section>
    </Modal>
  );
};

export default IdleTimeoutPopup;
